define("ember-svg-jar/inlined/file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6.667 32h19.502a2.667 2.667 0 002.666-2.667v-20.5c0-.639-.229-1.256-.645-1.74L22.885.927A2.667 2.667 0 0020.863 0H6.667A2.667 2.667 0 004 2.667v26.666A2.667 2.667 0 006.667 32z\" fill=\"#fff\"/><path d=\"M22.93 7V2l4.303 5h-4.302z\" fill=\"#fff\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M26.169 31.333H6.667a2 2 0 01-2-2V2.667a2 2 0 012-2h14.196A2 2 0 0122 1.02V8h5.987a2 2 0 01.182.832v20.501a2 2 0 01-2 2zM6.667 32h19.502a2.667 2.667 0 002.666-2.667v-20.5c0-.639-.229-1.256-.645-1.74L22.885.927A2.667 2.667 0 0020.863 0H6.667A2.667 2.667 0 004 2.667v26.666A2.667 2.667 0 006.667 32zM22.692 1.926v5.36h4.641l-4.64-5.36z\" fill=\"#1E1E1E\"/>",
    "attrs": {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});