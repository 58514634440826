define("ember-table/components/ember-tbody/component", ["exports", "@ember/component", "@ember/runloop", "@ember/object", "ember-table/-private/utils/observer", "@ember/object/computed", "ember-table/-private/collapse-tree", "ember-table/-private/utils/default-to", "ember-table/components/ember-tbody/template", "@ember/debug"], function (_exports, _component, _runloop, _object, _observer, _computed, _collapseTree, _defaultTo, _template, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.setSetupRowCountForTest = setSetupRowCountForTest;
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  var setupRowCountForTest = false;
  function setSetupRowCountForTest(bool) {
    setupRowCountForTest = bool;
  }

  /**
    The table body component. This component manages the main bulk of the rows of
    the table, provided occlusion for them and managing their behavior. It yields
    a template for each row, and an API that contains a row component, the row
    value, and the meta object for the row.
  
    ```hbs
    <EmberTable as |t|>
      <t.head @columns={{this.columns}} />
  
      <t.body @rows={{this.rows}} as |b|>
        <b.row>
      </t.body>
    </EmberTable>
    ```
  
    @yield {object} body - the API object yielded by the table body
    @yield {Component} body.row - The table row component
  
    @yield {object} body.rowValue - The value for the currently yielded row
    @yield {object} body.rowMeta - The meta for the currently yielded row
    @class <EmberTbody />
    @public
  */
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    tagName: 'tbody',
    /**
      The API object passed in by the table
       @argument api
      @required
      @type object
    */
    api: null,
    unwrappedApi: (0, _computed.or)('api.api', 'api'),
    columns: (0, _computed.readOnly)('unwrappedApi.columnTree.leaves'),
    columnMetaCache: (0, _computed.readOnly)('unwrappedApi.columnTree.columnMetaCache'),
    /**
      Sets which row selection behavior to follow. Possible values are 'none'
      (clicking on a row does nothing), 'single' (clicking on a row selects it and
      deselects other rows), and 'multiple' (multiple rows can be selected through
      ctrl/cmd-click or shift-click).
       @argument checkboxSelectionMode
      @type string? ('multiple')
    */
    checkboxSelectionMode: (0, _defaultTo.default)(_collapseTree.SELECT_MODE.MULTIPLE),
    /**
      Sets which checkbox selection behavior to follow. Possible values are 'none'
      (clicking on a row does nothing), 'single' (clicking on a row selects it and
      deselects other rows), and 'multiple' (multiple rows can be selected through
      ctrl/cmd-click or shift-click).
       @argument rowSelectionMode
      @type string? ('multiple')
    */
    rowSelectionMode: (0, _defaultTo.default)(_collapseTree.SELECT_MODE.MULTIPLE),
    /**
      When true, this option enables the toggling of rows without using the ctrlKey or metaKey.
       @argument rowToggleMode
      @type boolean
    */
    rowToggleMode: (0, _defaultTo.default)(false),
    /**
      When true, this option causes selecting all of a node's children to also
      select the node itself.
       @argument selectingChildrenSelectsParent
      @type boolean
    */
    selectingChildrenSelectsParent: (0, _defaultTo.default)(true),
    /**
      The currently selected rows. Can either be an array or an individual row.
       @argument selection
      @type array|object|null
    */
    selection: null,
    /**
      A function that will override how selection is compared to row value.
       @argument selectionMatchFunction
      @type function?
    */
    selectionMatchFunction: null,
    /**
      An action that is called when the row selection of the table changes.
      Will be called with either an array or individual row, depending on the
      checkboxSelectionMode.
       @argument onSelect
      @type Action?
      @param {object} selection - The new selection
    */
    onSelect: null,
    /**
      Estimated height for each row. This number is used to decide how many rows
      will be rendered at initial rendering.
       @argument estimateRowHeight
      @type number? (30)
    */
    estimateRowHeight: (0, _defaultTo.default)(30),
    /**
      A flag that controls if all rows have same static height or not. By default
      it is set to false and row height is dependent on its internal content. If
      it is set to true, all rows have the same height equivalent to
      estimateRowHeight.
       @argument staticHeight
      @type boolean? (false)
    */
    staticHeight: (0, _defaultTo.default)(false),
    /**
      The number of extra rows to render on either side of the table's viewport
       @argument bufferSize
      @type number? (1)
    */
    bufferSize: (0, _defaultTo.default)(1),
    /**
      A flag that tells the table to render all of its rows at once.
       @argument renderAll
      @type boolean? (false)
    */
    renderAll: (0, _defaultTo.default)(false),
    /**
      An action that is triggered when the table reaches the first row.
       @argument firstReached
      @type Action?
    */
    firstReached: null,
    /**
      An action that is triggered when the table reaches the last row.
       @argument lastReached
      @type Action?
    */
    lastReached: null,
    /**
      An action that is triggered when the first visible row of the table changes.
       @argument firstVisibleChanged
      @type Action?
    */
    firstVisibleChanged: null,
    /**
      An action that is triggered when the last visible row of the table changes.
       @argument lastVisibleChanged
      @type Action?
    */
    lastVisibleChanged: null,
    /**
      Boolean flag that enables tree behavior if items have a `children` property
       @argument enableTree
      @type boolean? (true)
    */
    enableTree: (0, _defaultTo.default)(true),
    /**
      Boolean flag that enables collapsing tree nodes
       @argument enableCollapse
      @type boolean? (true)
    */
    enableCollapse: (0, _defaultTo.default)(true),
    /**
      The row items that the table should display
       @argument rows
      @type array? ([])
    */
    rows: (0, _defaultTo.default)(function () {
      return [];
    }),
    /**
      This key is the property used by the collection to determine whether an
      array mutation is an append, prepend, or complete replacement. It is also
      the key that is passed to the actions, and can be used to restore scroll
      position with `idForFirstItem`. This is passed through to the
      vertical-collection.
       @argument key
      @type string? ('@identity')
    */
    key: (0, _defaultTo.default)('@identity'),
    /**
      The property is passed through to the vertical-collection. If set, upon initialization
      the scroll position will be set such that the item
      with the provided id is at the top left on screen.
      If the item with id cannot be found, scrollTop is set to 0.
       @argument idForFirstItem
      @type string?
    */
    idForFirstItem: null,
    /**
      A selector string that will select the element from
      which to calculate the viewable height.
       @argument containerSelector
      @type string? (<tableId>)
    */
    containerSelector: (0, _defaultTo.default)(''),
    /**
      Whether or not the table can select, is true if an `onSelect` action was
      passed to the table.
    */
    canSelect: (0, _computed.bool)('onSelect'),
    dataTestRowCount: null,
    attributeBindings: ['dataTestRowCount:data-test-row-count'],
    init: function init() {
      var _this$onSelect,
        _this = this;
      this._super.apply(this, arguments);

      /**
        The map that contains row meta information for this table.
      */
      this.rowMetaCache = new Map();

      /**
        A data structure that the table uses wrapping the `rows` object. It flattens
        the tree structure of the nodes and allows us to treat it as a flat list of
        items. This is much more convenient for most table operations in general.
      */
      this.collapseTree = _collapseTree.default.create({
        onSelect: (_this$onSelect = this.onSelect) === null || _this$onSelect === void 0 ? void 0 : _this$onSelect.bind(this)
      });
      this._updateCollapseTree();

      /*
       * Ember test selectors will remove data-test-row-count from the bindings,
       * so if it is missing there is no need to all the count.
       *
       * Even when ember-table is testing a production build, the you may want to
       * run tests which make assertions about row count. To implement that capability
       * reference a boolean variable controlled by the test helpers.
       */
      if (setupRowCountForTest) {
        this._isObservingDebugRowCount = true;
        var scheduleUpdate = this._scheduleUpdate = function () {
          (0, _runloop.scheduleOnce)('actions', _this, _this._updateDataTestRowCount);
        };
        this.collapseTree.addObserver('rows', scheduleUpdate);
        this.collapseTree.addObserver('[]', scheduleUpdate);
      }
      (true && !(!!this.get('unwrappedApi.columnTree')) && (0, _debug.assert)('You must create an <EmberThead /> with columns before creating an <EmberTbody />', !!this.get('unwrappedApi.columnTree')));
    },
    _updateDataTestRowCount: function _updateDataTestRowCount() {
      this.set('dataTestRowCount', this.get('collapseTree.length'));
    },
    // eslint-disable-next-line
    _updateCollapseTree: (0, _observer.observer)('unwrappedApi.{sorts,sortFunction,compareFunction,sortEmptyLast}', 'enableCollapse', 'enableTree', 'selection', 'selectionMatchFunction', 'selectingChildrenSelectsParent', 'onSelect', function () {
      this.collapseTree.set('sorts', this.get('unwrappedApi.sorts'));
      this.collapseTree.set('sortFunction', this.get('unwrappedApi.sortFunction'));
      this.collapseTree.set('compareFunction', this.get('unwrappedApi.compareFunction'));
      this.collapseTree.set('sortEmptyLast', this.get('unwrappedApi.sortEmptyLast'));
      this.collapseTree.set('enableCollapse', this.get('enableCollapse'));
      this.collapseTree.set('enableTree', this.get('enableTree'));
      this.collapseTree.set('selection', this.get('selection'));
      this.collapseTree.set('selectionMatchFunction', this.get('selectionMatchFunction'));
      this.collapseTree.set('selectingChildrenSelectsParent', this.get('selectingChildrenSelectsParent'));
    }),
    willDestroy: function willDestroy() {
      var _iterator = _createForOfIteratorHelper(this.rowMetaCache.entries()),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var _step$value = _slicedToArray(_step.value, 2),
            row = _step$value[0],
            meta = _step$value[1];
          meta.destroy();
          this.rowMetaCache.delete(row);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      if (this._isObservingDebugRowCount) {
        this.collapseTree.removeObserver('rows', this._scheduleUpdate);
        this.collapseTree.removeObserver('[]', this._scheduleUpdate);
      }
      this.collapseTree.destroy();
    },
    /**
      Computed property which updates the CollapseTree and erases caches. This is
      a computed for 1.12 compatibility, otherwise it would make sense to use
      lifecycle hooks instead.
    */
    wrappedRows: (0, _object.computed)('rows', function () {
      var rows = this.get('rows');
      this.collapseTree.set('rowMetaCache', this.rowMetaCache);
      this.collapseTree.set('rows', rows);
      return this.collapseTree;
    }),
    /**
      Computed property which calculates container selector for vertical collection.
      It can be a custom selector provided directly to <EmberTbody />.
      If not, it will be equal to parent <EmberTable /> `id`.
    */
    _containerSelector: (0, _object.computed)('containerSelector', 'unwrappedApi.tableId', function () {
      return this.get('containerSelector') || "#".concat(this.get('unwrappedApi.tableId'));
    })
  });
});