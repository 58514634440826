define("ember-svg-jar/inlined/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.25 2.5h1.576a2 2 0 012 2v1.117a7.417 7.417 0 011.739.998l.974-.563a2 2 0 012.732.732l.788 1.365a2 2 0 01-.732 2.732l-.942.544a7.476 7.476 0 01.009 2.088l.933.54a2 2 0 01.732 2.731l-.787 1.365a2 2 0 01-2.732.732l-.926-.534a7.42 7.42 0 01-1.788 1.036V20.5a2 2 0 01-2 2h-1.575a2 2 0 01-2-2v-1.117a7.418 7.418 0 01-1.817-1.058l-.963.556a2 2 0 01-2.732-.732l-.788-1.365a2 2 0 01.732-2.732l.995-.574a7.48 7.48 0 01.009-2.018l-1.004-.58a2 2 0 01-.732-2.731l.788-1.365a2 2 0 012.732-.732l1.013.585a7.417 7.417 0 011.767-1.02V4.5a2 2 0 012-2zm0 1.5a.5.5 0 00-.5.5v1.815a.53.53 0 01-.388.501A5.918 5.918 0 007.964 8.2a.53.53 0 01-.627.085L5.72 7.351a.5.5 0 00-.683.183L4.25 8.9a.5.5 0 00.183.683l1.608.928a.53.53 0 01.24.588 5.938 5.938 0 00-.013 2.75.53.53 0 01-.24.583l-1.595.92a.5.5 0 00-.183.683l.788 1.365a.5.5 0 00.683.183l1.572-.908a.53.53 0 01.631.088 5.917 5.917 0 002.438 1.422.53.53 0 01.389.501V20.5a.5.5 0 00.5.5h1.575a.5.5 0 00.5-.5v-1.815a.53.53 0 01.389-.501 5.918 5.918 0 002.415-1.4.53.53 0 01.629-.086l1.53.884a.5.5 0 00.683-.183l.788-1.365a.5.5 0 00-.183-.683l-1.535-.886a.53.53 0 01-.24-.586 5.937 5.937 0 00-.014-2.812.53.53 0 01.24-.59l1.549-.895a.5.5 0 00.183-.683l-.788-1.365a.5.5 0 00-.683-.183l-1.574.91a.53.53 0 01-.625-.083 5.916 5.916 0 00-2.375-1.362.53.53 0 01-.389-.501V4.5a.5.5 0 00-.5-.5h-1.575z\" fill=\"#1E1E1E\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.058 14.5a2 2 0 100-4 2 2 0 000 4zm0 1.5a3.5 3.5 0 100-7 3.5 3.5 0 000 7z\" fill=\"#1E1E1E\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});