define('@fortawesome/pro-light-svg-icons', ['exports'], (function (exports) { 'use strict';

  var faPencil = {
    prefix: 'fal',
    iconName: 'pencil',
    icon: [512, 512, [9999, 61504, "pencil-alt"], "f303", "M395.8 39.6c9.4-9.4 24.6-9.4 33.9 0l42.6 42.6c9.4 9.4 9.4 24.6 0 33.9L417.6 171 341 94.4l54.8-54.8zM318.4 117L395 193.6l-219 219V400c0-8.8-7.2-16-16-16H128V352c0-8.8-7.2-16-16-16H99.4l219-219zM66.9 379.5c1.2-4 2.7-7.9 4.7-11.5H96v32c0 8.8 7.2 16 16 16h32v24.4c-3.7 1.9-7.5 3.5-11.6 4.7L39.6 472.4l27.3-92.8zM452.4 17c-21.9-21.9-57.3-21.9-79.2 0L60.4 329.7c-11.4 11.4-19.7 25.4-24.2 40.8L.7 491.5c-1.7 5.6-.1 11.7 4 15.8s10.2 5.7 15.8 4l121-35.6c15.4-4.5 29.4-12.9 40.8-24.2L495 138.8c21.9-21.9 21.9-57.3 0-79.2L452.4 17zM331.3 202.7c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0l-128 128c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l128-128z"]
  };
  var faCaretRight = {
    prefix: 'fal',
    iconName: 'caret-right',
    icon: [256, 512, [], "f0da", "M221.8 250.4c1.3 1.4 2.2 3.5 2.2 5.6s-.8 4.2-2.2 5.6L108.3 381.8c-1.3 1.4-3.2 2.2-5.2 2.2c-3.9 0-7.1-3.2-7.1-7.1l0-241.7c0-3.9 3.2-7.1 7.1-7.1c2 0 3.8 .8 5.2 2.2L221.8 250.4zm23.3-22L131.6 108.3c-7.4-7.8-17.7-12.3-28.4-12.3C81.5 96 64 113.5 64 135.1l0 241.7c0 21.6 17.5 39.1 39.1 39.1c10.8 0 21.1-4.4 28.4-12.3L245.1 283.6c7-7.4 10.9-17.3 10.9-27.6s-3.9-20.1-10.9-27.6z"]
  };
  var faBars = {
    prefix: 'fal',
    iconName: 'bars',
    icon: [448, 512, ["navicon"], "f0c9", "M0 80c0-8.8 7.2-16 16-16H432c8.8 0 16 7.2 16 16s-7.2 16-16 16H16C7.2 96 0 88.8 0 80zM0 240c0-8.8 7.2-16 16-16H432c8.8 0 16 7.2 16 16s-7.2 16-16 16H16c-8.8 0-16-7.2-16-16zM448 400c0 8.8-7.2 16-16 16H16c-8.8 0-16-7.2-16-16s7.2-16 16-16H432c8.8 0 16 7.2 16 16z"]
  };
  var faLocationArrow = {
    prefix: 'fal',
    iconName: 'location-arrow',
    icon: [448, 512, [], "f124", "M224 224L48 224 400 80 256 432l0-176c0-17.7-14.3-32-32-32zm-32 32h32v32V432c0 15.3 10.8 28.4 25.8 31.4s30-5.1 35.8-19.3l144-352c4.9-11.9 2.1-25.6-7-34.7s-22.8-11.9-34.7-7l-352 144c-14.2 5.8-22.2 20.8-19.3 35.8s16.1 25.8 31.4 25.8H192z"]
  };
  var faRepeat = {
    prefix: 'fal',
    iconName: 'repeat',
    icon: [512, 512, [128257], "f363", "M15.2 272C6.4 271.5-.4 264 0 255.2l.4-8C5.1 153.5 82.4 80 176.2 80H320V35.6C320 20.3 332.3 8 347.6 8c6.8 0 13.4 2.5 18.4 7.1l76.7 69c3.4 3 5.3 7.4 5.3 11.9s-1.9 8.9-5.3 11.9l-76.7 69c-5.1 4.6-11.6 7.1-18.4 7.1c-15.2 0-27.6-12.3-27.6-27.6V112H176.2c-76.7 0-140 60.2-143.8 136.8l-.4 8c-.4 8.8-8 15.6-16.8 15.2zM352 146.5L408.1 96 352 45.5l0 100.9zM496.8 240c8.8 .4 15.6 8 15.2 16.8l-.4 8c-4.7 93.7-82 167.2-175.8 167.2L192 432l0 44.4c0 15.2-12.3 27.6-27.6 27.6c-6.8 0-13.4-2.5-18.4-7.1l-76.7-69c-3.4-3-5.3-7.4-5.3-11.9s1.9-8.9 5.3-11.9l76.7-69c5.1-4.6 11.6-7.1 18.4-7.1c15.2 0 27.6 12.3 27.6 27.6l0 44.4 143.8 0c76.7 0 140-60.2 143.8-136.8l.4-8c.4-8.8 8-15.6 16.8-15.2zM160 365.5L103.9 416 160 466.5l0-100.9z"]
  };
  var faEye = {
    prefix: 'fal',
    iconName: 'eye',
    icon: [576, 512, [128065], "f06e", "M117.2 136C160.3 96 217.6 64 288 64s127.7 32 170.8 72c43.1 40 71.9 88 85.2 120c-13.3 32-42.1 80-85.2 120c-43.1 40-100.4 72-170.8 72s-127.7-32-170.8-72C74.1 336 45.3 288 32 256c13.3-32 42.1-80 85.2-120zM288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM192 256a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zm224 0a128 128 0 1 0 -256 0 128 128 0 1 0 256 0z"]
  };
  var faArrowRotateRight = {
    prefix: 'fal',
    iconName: 'arrow-rotate-right',
    icon: [512, 512, [8635, "arrow-right-rotate", "arrow-rotate-forward", "redo"], "f01e", "M464 192c8.8 0 16-7.2 16-16V48c0-8.8-7.2-16-16-16s-16 7.2-16 16v92.6C408.8 75.5 337.5 32 256 32C132.3 32 32 132.3 32 256s100.3 224 224 224c79.9 0 150-41.8 189.7-104.8c6.6-10.4-1.4-23.2-13.7-23.2c-6 0-11.4 3.2-14.7 8.2C383.1 413 323.6 448 256 448C150 448 64 362 64 256S150 64 256 64c71.1 0 133.1 38.6 166.3 96H336c-8.8 0-16 7.2-16 16s7.2 16 16 16H464z"]
  };
  var faRedo = faArrowRotateRight;
  var faTrash = {
    prefix: 'fal',
    iconName: 'trash',
    icon: [448, 512, [], "f1f8", "M177.7 32h92.5c5.5 0 10.6 2.8 13.6 7.5L299.1 64H148.9l15.3-24.5c2.9-4.7 8.1-7.5 13.6-7.5zM336.9 64L311 22.6C302.2 8.5 286.8 0 270.3 0H177.7C161.2 0 145.8 8.5 137 22.6L111.1 64H64.1 32 16C7.2 64 0 71.2 0 80s7.2 16 16 16H34.3L59.8 452.6C62.1 486.1 90 512 123.6 512H324.4c33.6 0 61.4-25.9 63.8-59.4L413.7 96H432c8.8 0 16-7.2 16-16s-7.2-16-16-16H416 383.9 336.9zm44.8 32L356.3 450.3C355.1 467 341.2 480 324.4 480H123.6c-16.8 0-30.7-13-31.9-29.7L66.4 96H381.6z"]
  };
  var faEnvelope = {
    prefix: 'fal',
    iconName: 'envelope',
    icon: [512, 512, [128386, 9993, 61443], "f0e0", "M64 96c-17.7 0-32 14.3-32 32v39.9L227.6 311.3c16.9 12.4 39.9 12.4 56.8 0L480 167.9V128c0-17.7-14.3-32-32-32H64zM32 207.6V384c0 17.7 14.3 32 32 32H448c17.7 0 32-14.3 32-32V207.6L303.3 337.1c-28.2 20.6-66.5 20.6-94.6 0L32 207.6zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"]
  };
  var faMinus = {
    prefix: 'fal',
    iconName: 'minus',
    icon: [448, 512, [8211, 8722, 10134, "subtract"], "f068", "M432 256c0 8.8-7.2 16-16 16L32 272c-8.8 0-16-7.2-16-16s7.2-16 16-16l384 0c8.8 0 16 7.2 16 16z"]
  };
  var faEllipsisVertical = {
    prefix: 'fal',
    iconName: 'ellipsis-vertical',
    icon: [128, 512, ["ellipsis-v"], "f142", "M64 384a32 32 0 1 0 0 64 32 32 0 1 0 0-64zm0-160a32 32 0 1 0 0 64 32 32 0 1 0 0-64zM96 96A32 32 0 1 0 32 96a32 32 0 1 0 64 0z"]
  };
  var faEllipsisV = faEllipsisVertical;
  var faEllipsis = {
    prefix: 'fal',
    iconName: 'ellipsis',
    icon: [448, 512, ["ellipsis-h"], "f141", "M416 256a32 32 0 1 1 -64 0 32 32 0 1 1 64 0zm-160 0a32 32 0 1 1 -64 0 32 32 0 1 1 64 0zM64 288a32 32 0 1 1 0-64 32 32 0 1 1 0 64z"]
  };
  var faEllipsisH = faEllipsis;
  var faLocationDot = {
    prefix: 'fal',
    iconName: 'location-dot',
    icon: [384, 512, ["map-marker-alt"], "f3c5", "M352 192c0-88.4-71.6-160-160-160S32 103.6 32 192c0 15.6 5.4 37 16.6 63.4c10.9 25.9 26.2 54 43.6 82.1c34.1 55.3 74.4 108.2 99.9 140c25.4-31.8 65.8-84.7 99.9-140c17.3-28.1 32.7-56.3 43.6-82.1C346.6 229 352 207.6 352 192zm32 0c0 87.4-117 243-168.3 307.2c-12.3 15.3-35.1 15.3-47.4 0C117 435 0 279.4 0 192C0 86 86 0 192 0S384 86 384 192zm-240 0a48 48 0 1 0 96 0 48 48 0 1 0 -96 0zm48 80a80 80 0 1 1 0-160 80 80 0 1 1 0 160z"]
  };
  var faMapMarkerAlt = faLocationDot;
  var faPlus = {
    prefix: 'fal',
    iconName: 'plus',
    icon: [448, 512, [10133, 61543, "add"], "2b", "M240 64c0-8.8-7.2-16-16-16s-16 7.2-16 16V240H32c-8.8 0-16 7.2-16 16s7.2 16 16 16H208V448c0 8.8 7.2 16 16 16s16-7.2 16-16V272H416c8.8 0 16-7.2 16-16s-7.2-16-16-16H240V64z"]
  };
  var faXmark = {
    prefix: 'fal',
    iconName: 'xmark',
    icon: [384, 512, [128473, 10005, 10006, 10060, 215, "close", "multiply", "remove", "times"], "f00d", "M324.5 411.1c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L214.6 256 347.1 123.5c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L192 233.4 59.5 100.9c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L169.4 256 36.9 388.5c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L192 278.6 324.5 411.1z"]
  };
  var faTimes = faXmark;
  var faWandMagic = {
    prefix: 'fal',
    iconName: 'wand-magic',
    icon: [512, 512, ["magic"], "f0d0", "M305.9 155.3l50.7 50.7L477.1 85.7c3.1-3.1 3.1-8.2 0-11.3L437.7 34.9c-3.1-3.1-8.2-3.1-11.3 0L305.9 155.3zm-22.6 22.6L34.9 426.3c-3.1 3.1-3.1 8.2 0 11.3l39.4 39.4c3.1 3.1 8.2 3.1 11.3 0L334.1 228.7l-50.7-50.7zM12.3 403.7L403.7 12.3c15.6-15.6 40.9-15.6 56.6 0l39.4 39.4c15.6 15.6 15.6 40.9 0 56.6L108.3 499.7c-15.6 15.6-40.9 15.6-56.6 0L12.3 460.3c-15.6-15.6-15.6-40.9 0-56.6z"]
  };
  var faMagic = faWandMagic;
  var faCheck = {
    prefix: 'fal',
    iconName: 'check',
    icon: [448, 512, [10003, 10004], "f00c", "M443.3 100.7c6.2 6.2 6.2 16.4 0 22.6l-272 272c-6.2 6.2-16.4 6.2-22.6 0l-144-144c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L160 361.4 420.7 100.7c6.2-6.2 16.4-6.2 22.6 0z"]
  };
  var faTriangleExclamation = {
    prefix: 'fal',
    iconName: 'triangle-exclamation',
    icon: [512, 512, [9888, "exclamation-triangle", "warning"], "f071", "M34.5 420.4c-1.6 2.8-2.5 6-2.5 9.3c0 10.2 8.2 18.4 18.4 18.4H461.6c10.2 0 18.4-8.2 18.4-18.4c0-3.3-.9-6.4-2.5-9.3L276.5 75.8C272.2 68.5 264.4 64 256 64s-16.2 4.5-20.5 11.8L34.5 420.4zM6.9 404.2l201-344.6C217.9 42.5 236.2 32 256 32s38.1 10.5 48.1 27.6l201 344.6c4.5 7.7 6.9 16.5 6.9 25.4c0 27.8-22.6 50.4-50.4 50.4H50.4C22.6 480 0 457.4 0 429.6c0-8.9 2.4-17.7 6.9-25.4zM256 160c8.8 0 16 7.2 16 16V304c0 8.8-7.2 16-16 16s-16-7.2-16-16V176c0-8.8 7.2-16 16-16zM232 384a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z"]
  };
  var faExclamationTriangle = faTriangleExclamation;

  exports.faBars = faBars;
  exports.faCaretRight = faCaretRight;
  exports.faCheck = faCheck;
  exports.faEllipsisH = faEllipsisH;
  exports.faEllipsisV = faEllipsisV;
  exports.faEnvelope = faEnvelope;
  exports.faExclamationTriangle = faExclamationTriangle;
  exports.faEye = faEye;
  exports.faLocationArrow = faLocationArrow;
  exports.faMagic = faMagic;
  exports.faMapMarkerAlt = faMapMarkerAlt;
  exports.faMinus = faMinus;
  exports.faPencil = faPencil;
  exports.faPlus = faPlus;
  exports.faRedo = faRedo;
  exports.faRepeat = faRepeat;
  exports.faTimes = faTimes;
  exports.faTrash = faTrash;

  Object.defineProperty(exports, '__esModule', { value: true });

}));
