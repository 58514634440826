define("ember-collapsible-panel/components/cp-panel-body/component", ["exports", "@embroider/util", "@ember/component", "@ember/object", "@ember/debug", "ember-collapsible-panel/components/cp-panel-body/template"], function (_exports, _util, _component, _object, _debug, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-classic-classes, ember/no-classic-components, ember/require-tagless-components, prettier/prettier */

  var LiquidIf, hasRealLiquidIf;
  {
    hasRealLiquidIf = false;
  }
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    classNames: ['cp-Panel-body'],
    classNameBindings: ['isOpen:cp-is-open'],
    // ideally we'd use a getter here, but that requires Ember 3.16+
    'liquidif': (0, _object.computed)(function () {
      (true && !(hasRealLiquidIf) && (0, _debug.assert)('ember-collapsible-panel\'s cp-panel-body component requires liquid-fire to be present if `shouldAnimate` is used', hasRealLiquidIf));
      return (0, _util.ensureSafeComponent)(LiquidIf, this);
    })
  });
});