define("ember-local-storage/mixins/array", ["exports", "@ember/array", "@ember/object/mixin", "@ember/object", "ember-local-storage/mixins/storage", "ember-local-storage/helpers/utils"], function (_exports, _array, _mixin, _object, _storage, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _mixin.default.create(_storage.default, {
    _initialContent: (0, _array.A)(),
    _clear: function _clear() {
      (0, _object.set)(this, 'content', (0, _array.A)());
    },
    replaceContent: _utils.save,
    reset: _utils.save
  });
});