define("ember-svg-jar/inlined/dashboard-operations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9.32 11.745c.007.722.468 1.255.993 1.255h5.285c.756 0 1.348.322 1.742.813.383.478.56 1.094.56 1.687s-.177 1.21-.56 1.688c-.394.49-.986.812-1.742.812H4a.5.5 0 010-1h11.598c.446 0 .754-.178.96-.438.218-.271.341-.655.341-1.062 0-.407-.123-.79-.34-1.063-.207-.258-.515-.437-.96-.437h-5.286c-1.288 0-1.983-1.217-1.994-2.245-.005-.542.166-1.113.581-1.552.422-.445 1.045-.703 1.842-.703H14a.5.5 0 010 1h-3.258c-.574 0-.916.18-1.115.39-.206.218-.311.522-.308.855z\" fill=\"#505152\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M5.26 14.557C6.204 13.517 7 12.221 7 10.871 7 9.316 5.688 8 4 8s-3 1.316-3 2.871c0 1.35.796 2.647 1.74 3.686.456.501.914.908 1.26 1.19.346-.282.804-.689 1.26-1.19zM4 17s4-2.679 4-6.129C8 8.733 6.21 7 4 7s-4 1.733-4 3.871C0 14.321 4 17 4 17zM15.26 7.557C16.204 6.517 17 5.221 17 3.871 17 2.316 15.688 1 14 1s-3 1.316-3 2.871c0 1.35.796 2.647 1.74 3.686.456.501.914.908 1.26 1.19.346-.282.804-.689 1.26-1.19zM14 10s4-2.679 4-6.129C18 1.733 16.21 0 14 0s-4 1.733-4 3.871C10 7.321 14 10 14 10z\" fill=\"#505152\"/><path d=\"M5 11a1 1 0 11-2 0 1 1 0 012 0zM15 4a1 1 0 11-2 0 1 1 0 012 0z\" fill=\"#505152\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});