define("ember-svg-jar/inlined/order", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.333 7.13L11.773 4m0 0l-2.44 3.13M11.773 4v7.666\" stroke=\"#A0A0A0\" stroke-linecap=\"round\"/><path d=\"M2 8.8L4.56 12m0 0L7 8.8M4.56 12V4\" stroke=\"#1E1E1E\" stroke-linecap=\"round\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});