define("ember-svg-jar/inlined/notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M5.844 9a4.75 4.75 0 014.75-4.75h2.77A4.75 4.75 0 0118.113 9v5.525l2.013 3.063a.75.75 0 01-.627 1.162h-15a.75.75 0 01-.63-1.156l1.974-3.065V9zm4.75-3.25A3.25 3.25 0 007.344 9v5.75a.75.75 0 01-.12.406L5.875 17.25H18.11l-1.372-2.088a.75.75 0 01-.124-.412V9a3.25 3.25 0 00-3.25-3.25h-2.769z\" fill=\"#1E1E1E\"/><path d=\"M11 4a1 1 0 112 0v1h-2V4z\" fill=\"#1E1E1E\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.25 4a1.75 1.75 0 113.5 0v1.75h-3.5V4zM12 3.75a.25.25 0 00-.25.25v.25h.5V4a.25.25 0 00-.25-.25zM15.75 18a3.75 3.75 0 11-7.5 0H9v-.75h6V18h.75zm-1.628.75H9.878a2.25 2.25 0 004.244 0z\" fill=\"#1E1E1E\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});