define("ember-drag-drop/utils/proxy-unproxy-objects", ["exports", "@ember/utils", "@ember/object/internals"], function (_exports, _utils, _internals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unwrapper = unwrapper;
  _exports.wrapper = wrapper;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  /**
   * On drag action we need to provide a property `isDraggingObject` to the UI.
   * This utility is used to create a wrapper object around the object passed to the proxy function.
   * We use this wrapper to prevent the `draggable-object` from mutating the original object by appending
   * `isDraggingObject` to the content.
   * 
   * This unexpected mutation causes problems when the targeted content is not prepared to handle
   * the additional property, and potentially leaks local state onto an object that likely holds state
   * for the route or application more generally.
   */

  /**
   * @access public
   * Returns the passed object wrapped within new object.
   * Used to proxy draggable objects.
   * @param objectToProxy Object to proxy.
   * @returns {Object} Proxy object.
   */
  function wrapper(objectToProxy) {
    // If we do not have any content for the object to proxy, 
    // we do not wish to render that item.
    if (!(0, _utils.isNone)(objectToProxy)) {
      var guidKey = (0, _internals.guidFor)(objectToProxy);
      return _defineProperty(_defineProperty(_defineProperty({}, guidKey, objectToProxy), "unwrappingKey", guidKey), "id", objectToProxy.id);
    }
    return null;
  }

  /**
   * @access public
   * Returns the content of the passed object.
   * Used to un-proxy draggable objects.
   * @param objectToUnproxy Object to un-proxy.
   * @returns {Object} Content of the proxy object.
   */
  function unwrapper(objectToUnproxy) {
    if (!(0, _utils.isNone)(objectToUnproxy)) {
      return objectToUnproxy[objectToUnproxy.unwrappingKey];
    }
    return null;
  }
});