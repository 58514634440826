define("ember-svg-jar/inlined/app-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M2.25 0A2.25 2.25 0 000 2.25v2.5A2.25 2.25 0 002.25 7h2.5A2.25 2.25 0 007 4.75v-2.5A2.25 2.25 0 004.75 0h-2.5zM1.5 2.25a.75.75 0 01.75-.75h2.5a.75.75 0 01.75.75v2.5a.75.75 0 01-.75.75h-2.5a.75.75 0 01-.75-.75v-2.5zM9.25 1a.75.75 0 000 1.5h10a.75.75 0 100-1.5h-10zm0 3a.75.75 0 000 1.5h7a.75.75 0 100-1.5h-7zm-7 5A2.25 2.25 0 000 11.25v2.5A2.25 2.25 0 002.25 16h2.5A2.25 2.25 0 007 13.75v-2.5A2.25 2.25 0 004.75 9h-2.5zm-.75 2.25a.75.75 0 01.75-.75h2.5a.75.75 0 01.75.75v2.5a.75.75 0 01-.75.75h-2.5a.75.75 0 01-.75-.75v-2.5zM9.25 10a.75.75 0 100 1.5h10a.75.75 0 100-1.5h-10zm0 3a.75.75 0 100 1.5h7a.75.75 0 100-1.5h-7z\" fill=\"#4482E1\"/>",
    "attrs": {
      "width": "20",
      "height": "16",
      "viewBox": "0 0 20 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});