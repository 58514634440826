define("ember-svg-jar/inlined/calendar-16px", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11 2.167a.5.5 0 01.5.5v1.116h.833a1.5 1.5 0 011.5 1.5v7.05a1.5 1.5 0 01-1.5 1.5H3.667a1.5 1.5 0 01-1.5-1.5v-7.05a1.5 1.5 0 011.5-1.5h.88V2.667a.5.5 0 011 0v1.116H10.5V2.667a.5.5 0 01.5-.5zm-.5 2.616H5.548v.646a.5.5 0 11-1 0v-.646h-.881a.5.5 0 00-.5.5v1.943h9.666V5.283a.5.5 0 00-.5-.5H11.5v.646a.5.5 0 01-1 0v-.646zm2.333 3.443H3.167v4.107a.5.5 0 00.5.5h8.666a.5.5 0 00.5-.5V8.226z\" fill=\"#A0A0A0\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});