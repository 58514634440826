define("ember-table/components/ember-th/component", ["exports", "ember-table/components/-private/base-table-cell", "@ember/runloop", "@ember/object/computed", "ember-table/-private/utils/element", "ember-table/components/ember-th/template", "@ember/object"], function (_exports, _baseTableCell, _runloop, _computed, _element, _template, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; } /* global Hammer */
  var COLUMN_INACTIVE = 0;
  var COLUMN_RESIZING = 1;
  var COLUMN_REORDERING = 2;

  /**
    The table header cell component. This component manages header cell level
    concerns, and yields the column value and column meta data objects.
  
    ```hbs
    <EmberTable as |t|>
      <t.head @columns={{this.columns}} as |h|>
        <h.row as |r|>
          <r.cell as |columnValue columnMeta|>
  
          </r.cell>
        </h.row>
      </t.head>
  
      <t.body @rows={{this.rows}} />
    </EmberTable>
    ```
    @yield {object} columnValue - The column definition
    @yield {object} columnMeta - The meta object associated with this column
    @class <EmberTh />
    @public
  */
  var _default = _exports.default = _baseTableCell.default.extend({
    layout: _template.default,
    tagName: 'th',
    attributeBindings: ['columnSpan:colspan', 'rowSpan:rowspan'],
    classNameBindings: ['isSortable', 'isResizable', 'isReorderable'],
    /**
      The API object passed in by the table row
      @argument api
      @required
      @type object
    */
    api: null,
    /**
      Action sent when the user clicks right this element
      @argument onContextMenu
      @type Action?
    */
    onContextMenu: null,
    columnValue: (0, _computed.readOnly)('api.columnValue'),
    columnMeta: (0, _computed.readOnly)('api.columnMeta'),
    rowMeta: (0, _computed.readOnly)('api.rowMeta'),
    /**
      Any sorts applied to the table.
    */
    sorts: (0, _computed.readOnly)('api.sorts'),
    /**
      Whether or not the column is sortable. Is true IFF the column is a leaf node
      onUpdateSorts is set on the thead.
    */
    isSortable: (0, _computed.readOnly)('columnMeta.isSortable'),
    /**
      Indicates if this column can be resized.
    */
    isResizable: (0, _computed.readOnly)('columnMeta.isResizable'),
    /**
     Indicates if this column can be reordered.
    */
    isReorderable: (0, _computed.readOnly)('columnMeta.isReorderable'),
    columnSpan: (0, _computed.readOnly)('columnMeta.columnSpan'),
    rowSpan: (0, _computed.readOnly)('columnMeta.rowSpan'),
    /**
      A variable used for column resizing & ordering. When user press mouse at a point that's close
      to column boundary (using some threshold), this variable set whether it's the left or right
      column.
    */
    _columnState: COLUMN_INACTIVE,
    /**
      An object that listens to touch/ press/ drag events.
    */
    _hammer: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.get('columnMeta').registerElement(this.element);
      var hammer = new Hammer(this.element);
      hammer.add(new Hammer.Press({
        time: 0
      }));
      hammer.on('press', this.pressHandler.bind(this));
      hammer.on('panstart', this.panStartHandler.bind(this));
      hammer.on('panmove', this.panMoveHandler.bind(this));
      hammer.on('panend', this.panEndHandler.bind(this));
      this._hammer = hammer;
    },
    willDestroyElement: function willDestroyElement() {
      var hammer = this._hammer;
      hammer.off('press');
      hammer.off('panstart');
      hammer.off('panmove');
      hammer.off('panend');
      hammer.destroy();
      this._super.apply(this, arguments);
    },
    actions: {
      sendDropdownAction: function sendDropdownAction() {
        var _this$onDropdownActio;
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }
        (_this$onDropdownActio = this.onDropdownAction) === null || _this$onDropdownActio === void 0 ? void 0 : _this$onDropdownActio.call.apply(_this$onDropdownActio, [this].concat(args));
      }
    },
    click: function click(event) {
      var isSortable = this.get('isSortable');
      var inputParent = (0, _element.closest)(event.target, 'button:not(.et-sort-toggle), input, label, a, select');
      if (this._columnState === COLUMN_INACTIVE && !inputParent && isSortable) {
        var toggle = event.ctrlKey || event.metaKey;
        this.updateSort({
          toggle: toggle
        });
      }
    },
    contextMenu: function contextMenu(event) {
      var _this$onContextMenu;
      (_this$onContextMenu = this.onContextMenu) === null || _this$onContextMenu === void 0 ? void 0 : _this$onContextMenu.call(this, event);
      return false;
    },
    keyUp: function keyUp(event) {
      var isSortable = this.get('isSortable');
      var inputParent = (0, _element.closest)(event.target, 'button:not(.et-sort-toggle), input, label, a, select');
      if (this._columnState === COLUMN_INACTIVE && !inputParent && event.key === 'Enter' && isSortable) {
        this.updateSort({
          toggle: false
        });
      }
    },
    updateSort: function updateSort(_ref) {
      var toggle = _ref.toggle;
      var valuePath = this.get('columnValue.valuePath');
      var sorts = this.get('sorts');
      var existingSorting = sorts.find(function (s) {
        return (0, _object.get)(s, 'valuePath') === valuePath;
      });
      var newSortings = toggle ? sorts.filter(function (s) {
        return (0, _object.get)(s, 'valuePath') !== valuePath;
      }) : [];
      if (!existingSorting) {
        newSortings.push({
          valuePath: valuePath,
          isAscending: false
        });
      } else if (existingSorting.isAscending === false) {
        newSortings.push({
          valuePath: valuePath,
          isAscending: true
        });
      }
      this.get('api').sendUpdateSort(newSortings);
    },
    pressHandler: function pressHandler(event) {
      var _event$pointers = _slicedToArray(event.pointers, 1),
        _event$pointers$ = _event$pointers[0],
        clientX = _event$pointers$.clientX,
        target = _event$pointers$.target;
      this._originalClientX = clientX;
      this._originalTargetWasResize = target.classList.contains('et-header-resize-area');
    },
    panStartHandler: function panStartHandler(event) {
      var isResizable = this.get('isResizable');
      var isReorderable = this.get('isReorderable');
      var _event$pointers2 = _slicedToArray(event.pointers, 1),
        clientX = _event$pointers2[0].clientX;
      if (isResizable && this._originalTargetWasResize) {
        this._columnState = COLUMN_RESIZING;
        this.get('columnMeta').startResize(this._originalClientX);
      } else if (isReorderable) {
        this._columnState = COLUMN_REORDERING;
        this.get('columnMeta').startReorder(clientX);
      }
    },
    panMoveHandler: function panMoveHandler(event) {
      var _event$pointers3 = _slicedToArray(event.pointers, 1),
        clientX = _event$pointers3[0].clientX;
      if (this._columnState === COLUMN_RESIZING) {
        this.get('columnMeta').updateResize(clientX);
        this._prevClientX = clientX;
      } else if (this._columnState === COLUMN_REORDERING) {
        this.get('columnMeta').updateReorder(clientX);
        this._columnState = COLUMN_REORDERING;
      }
    },
    panEndHandler: function panEndHandler() {
      var _this = this;
      if (this._columnState === COLUMN_RESIZING) {
        this.get('columnMeta').endResize();
      } else if (this._columnState === COLUMN_REORDERING) {
        this.get('columnMeta').endReorder();
      }
      (0, _runloop.next)(function () {
        return _this._columnState = COLUMN_INACTIVE;
      });
    }
  });
});