define("ember-svg-jar/inlined/dashboard-templates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11 1H3v16h12V6.125h-3a1 1 0 01-1-1V1zm4.827 4.125a.81.81 0 01.173.5V17a1 1 0 01-1 1H3a1 1 0 01-1-1V1a1 1 0 011-1h8.5L14 2.813l1.826 2.31.001.002zm-2.594-1.67l1.32 1.67H12V2.068l1.233 1.387z\" fill=\"#505152\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4.5 8a.5.5 0 01.5-.5h3.733a.5.5 0 110 1H5a.5.5 0 01-.5-.5zm0 2.88a.5.5 0 01.5-.5h8a.5.5 0 010 1H5a.5.5 0 01-.5-.5zm0 3.12a.5.5 0 01.5-.5h8a.5.5 0 010 1H5a.5.5 0 01-.5-.5z\" fill=\"#505152\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});