define("ember-svg-jar/inlined/document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 1.5V3c0 1.414 0 2.121.44 2.56C12.878 6 13.585 6 15 6h1.5\" stroke=\"#4482E1\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M5 10h8m-8 3h8m-8 3h4.17M1 15V7c0-2.828 0-4.243.879-5.121C2.757 1 4.172 1 7 1h4.172c.408 0 .613 0 .797.076.183.076.328.22.617.51l3.828 3.828c.29.29.434.434.51.618.076.183.076.388.076.796V15c0 2.828 0 4.243-.879 5.121C15.243 21 13.828 21 11 21H7c-2.828 0-4.243 0-5.121-.879C1 19.243 1 17.828 1 15z\" stroke=\"#4482E1\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "18",
      "height": "22",
      "viewBox": "0 0 18 22",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});