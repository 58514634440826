define('@fortawesome/pro-regular-svg-icons', ['exports'], (function (exports) { 'use strict';

  var faPencil = {
    prefix: 'far',
    iconName: 'pencil',
    icon: [512, 512, [9999, 61504, "pencil-alt"], "f303", "M36.4 360.9L13.4 439 1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1L73 498.6l78.1-23c10.4-3 20.1-8 28.6-14.5l.3 .2 .5-.8c1.4-1.1 2.7-2.2 4-3.3c1.4-1.2 2.7-2.5 4-3.8L492.7 149.3c21.9-21.9 24.6-55.6 8.2-80.5c-2.3-3.5-5.1-6.9-8.2-10L453.3 19.3c-25-25-65.5-25-90.5 0L58.6 323.5c-2.5 2.5-4.9 5.2-7.1 8l-.8 .5 .2 .3c-6.5 8.5-11.4 18.2-14.5 28.6zM383 191L197.4 376.6l-49.6-12.4-12.4-49.6L321 129 383 191zM97 358.9l7.7 31c2.1 8.6 8.9 15.3 17.5 17.5l31 7.7-7.4 11.2c-2.6 1.4-5.3 2.6-8.1 3.4l-23.4 6.9L59.4 452.6l16.1-54.8 6.9-23.4c.8-2.8 2-5.6 3.4-8.1L97 358.9zM315.3 218.7c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0l-96 96c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l96-96z"]
  };
  var faCaretRight = {
    prefix: 'far',
    iconName: 'caret-right',
    icon: [256, 512, [], "f0da", "M201.4 256L112 166.6l0 178.7L201.4 256zm45.3-22.6c12.5 12.5 12.5 32.8 0 45.3l-128 128c-9.2 9.2-22.9 11.9-34.9 6.9s-19.8-16.6-19.8-29.6l0-256c0-12.9 7.8-24.6 19.8-29.6s25.7-2.2 34.9 6.9l128 128z"]
  };
  var faBars = {
    prefix: 'far',
    iconName: 'bars',
    icon: [448, 512, ["navicon"], "f0c9", "M0 88C0 74.7 10.7 64 24 64H424c13.3 0 24 10.7 24 24s-10.7 24-24 24H24C10.7 112 0 101.3 0 88zM0 248c0-13.3 10.7-24 24-24H424c13.3 0 24 10.7 24 24s-10.7 24-24 24H24c-13.3 0-24-10.7-24-24zM448 408c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24s10.7-24 24-24H424c13.3 0 24 10.7 24 24z"]
  };
  var faLocationArrow = {
    prefix: 'far',
    iconName: 'location-arrow',
    icon: [448, 512, [], "f124", "M224 208H129.4l241.4-98.7L272 350.6V256c0-26.5-21.5-48-48-48zm-48 48h48v48V432c0 15.3 10.8 28.4 25.8 31.4s30-5.1 35.8-19.3l144-352c4.9-11.9 2.1-25.6-7-34.7s-22.8-11.9-34.7-7l-352 144c-14.2 5.8-22.2 20.8-19.3 35.8s16.1 25.8 31.4 25.8H176z"]
  };
  var faRepeat = {
    prefix: 'far',
    iconName: 'repeat',
    icon: [512, 512, [128257], "f363", "M22.8 280C9.6 279.3-.6 268 0 254.8l.4-8C5.3 148.9 86.2 72 184.2 72H304l0-36.4C304 15.9 319.9 0 339.6 0c8.8 0 17.3 3.3 23.8 9.1l76.7 69c5.1 4.6 7.9 11 7.9 17.8s-2.9 13.3-7.9 17.8l-76.7 69c-6.5 5.9-15 9.1-23.8 9.1c-19.6 0-35.6-15.9-35.6-35.6V120H184.2C111.7 120 52 176.8 48.4 249.2l-.4 8C47.3 270.4 36 280.6 22.8 280zM352 128.5L388.1 96 352 63.5l0 65zM489.2 232c13.2 .7 23.4 11.9 22.8 25.2l-.4 8C506.7 363.1 425.8 440 327.8 440L208 440v36.4c0 19.6-15.9 35.6-35.6 35.6c-8.8 0-17.3-3.3-23.8-9.1l-76.7-69c-5.1-4.6-7.9-11-7.9-17.8s2.9-13.3 7.9-17.8l76.7-69c6.5-5.9 15-9.1 23.8-9.1c19.6 0 35.6 15.9 35.6 35.6V392l119.8 0c72.5 0 132.2-56.8 135.8-129.2l.4-8c.7-13.2 11.9-23.4 25.2-22.8zM160 383.5L123.9 416 160 448.5v-65z"]
  };
  var faEye = {
    prefix: 'far',
    iconName: 'eye',
    icon: [576, 512, [128065], "f06e", "M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z"]
  };
  var faArrowRotateRight = {
    prefix: 'far',
    iconName: 'arrow-rotate-right',
    icon: [512, 512, [8635, "arrow-right-rotate", "arrow-rotate-forward", "redo"], "f01e", "M472 224c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24s-24 10.7-24 24v80.1l-20-23.5C387 63.4 325.1 32 256 32C132.3 32 32 132.3 32 256s100.3 224 224 224c50.4 0 97-16.7 134.4-44.8c10.6-8 12.7-23 4.8-33.6s-23-12.7-33.6-4.8C332.2 418.9 295.7 432 256 432c-97.2 0-176-78.8-176-176s78.8-176 176-176c54.3 0 102.9 24.6 135.2 63.4l.1 .2 0 0L418.9 176H328c-13.3 0-24 10.7-24 24s10.7 24 24 24H472z"]
  };
  var faRedo = faArrowRotateRight;
  var faTrash = {
    prefix: 'far',
    iconName: 'trash',
    icon: [448, 512, [], "f1f8", "M177.1 48h93.7c2.7 0 5.2 1.3 6.7 3.6l19 28.4h-145l19-28.4c1.5-2.2 4-3.6 6.7-3.6zM354.2 80L317.5 24.9C307.1 9.4 289.6 0 270.9 0H177.1c-18.7 0-36.2 9.4-46.6 24.9L93.8 80H80.1 32 24C10.7 80 0 90.7 0 104s10.7 24 24 24H35.6L59.6 452.7c2.5 33.4 30.3 59.3 63.8 59.3H324.6c33.5 0 61.3-25.9 63.8-59.3L412.4 128H424c13.3 0 24-10.7 24-24s-10.7-24-24-24h-8H367.9 354.2zm10.1 48L340.5 449.2c-.6 8.4-7.6 14.8-16 14.8H123.4c-8.4 0-15.3-6.5-16-14.8L83.7 128H364.3z"]
  };
  var faEnvelope = {
    prefix: 'far',
    iconName: 'envelope',
    icon: [512, 512, [128386, 9993, 61443], "f0e0", "M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"]
  };
  var faMinus = {
    prefix: 'far',
    iconName: 'minus',
    icon: [448, 512, [8211, 8722, 10134, "subtract"], "f068", "M432 256c0 13.3-10.7 24-24 24L40 280c-13.3 0-24-10.7-24-24s10.7-24 24-24l368 0c13.3 0 24 10.7 24 24z"]
  };
  var faEllipsisVertical = {
    prefix: 'far',
    iconName: 'ellipsis-vertical',
    icon: [128, 512, ["ellipsis-v"], "f142", "M64 368a48 48 0 1 0 0 96 48 48 0 1 0 0-96zm0-160a48 48 0 1 0 0 96 48 48 0 1 0 0-96zM112 96A48 48 0 1 0 16 96a48 48 0 1 0 96 0z"]
  };
  var faEllipsisV = faEllipsisVertical;
  var faEllipsis = {
    prefix: 'far',
    iconName: 'ellipsis',
    icon: [448, 512, ["ellipsis-h"], "f141", "M432 256a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zm-160 0a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zM64 304a48 48 0 1 1 0-96 48 48 0 1 1 0 96z"]
  };
  var faEllipsisH = faEllipsis;
  var faLocationDot = {
    prefix: 'far',
    iconName: 'location-dot',
    icon: [384, 512, ["map-marker-alt"], "f3c5", "M336 192c0-79.5-64.5-144-144-144S48 112.5 48 192c0 12.4 4.5 31.6 15.3 57.2c10.5 24.8 25.4 52.2 42.5 79.9c28.5 46.2 61.5 90.8 86.2 122.6c24.8-31.8 57.8-76.4 86.2-122.6c17.1-27.7 32-55.1 42.5-79.9C331.5 223.6 336 204.4 336 192zm48 0c0 87.4-117 243-168.3 307.2c-12.3 15.3-35.1 15.3-47.4 0C117 435 0 279.4 0 192C0 86 86 0 192 0S384 86 384 192zm-160 0a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm-112 0a80 80 0 1 1 160 0 80 80 0 1 1 -160 0z"]
  };
  var faMapMarkerAlt = faLocationDot;
  var faPlus = {
    prefix: 'far',
    iconName: 'plus',
    icon: [448, 512, [10133, 61543, "add"], "2b", "M248 72c0-13.3-10.7-24-24-24s-24 10.7-24 24V232H40c-13.3 0-24 10.7-24 24s10.7 24 24 24H200V440c0 13.3 10.7 24 24 24s24-10.7 24-24V280H408c13.3 0 24-10.7 24-24s-10.7-24-24-24H248V72z"]
  };
  var faXmark = {
    prefix: 'far',
    iconName: 'xmark',
    icon: [384, 512, [128473, 10005, 10006, 10060, 215, "close", "multiply", "remove", "times"], "f00d", "M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z"]
  };
  var faTimes = faXmark;
  var faWandMagic = {
    prefix: 'far',
    iconName: 'wand-magic',
    icon: [512, 512, ["magic"], "f0d0", "M319.7 157.7l34.6 34.6L464 82.6 429.4 48 319.7 157.7zm-33.9 33.9L48 429.4 82.6 464 320.3 226.2l-34.6-34.6zM14.1 395.4L395.4 14.1c18.7-18.7 49.1-18.7 67.9 0l34.6 34.6c18.7 18.7 18.7 49.1 0 67.9L116.5 497.9c-18.7 18.7-49.1 18.7-67.9 0L14.1 463.3c-18.7-18.7-18.7-49.1 0-67.9z"]
  };
  var faMagic = faWandMagic;
  var faCheck = {
    prefix: 'far',
    iconName: 'check',
    icon: [448, 512, [10003, 10004], "f00c", "M441 103c9.4 9.4 9.4 24.6 0 33.9L177 401c-9.4 9.4-24.6 9.4-33.9 0L7 265c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l119 119L407 103c9.4-9.4 24.6-9.4 33.9 0z"]
  };
  var faTriangleExclamation = {
    prefix: 'far',
    iconName: 'triangle-exclamation',
    icon: [512, 512, [9888, "exclamation-triangle", "warning"], "f071", "M248.4 84.3c1.6-2.7 4.5-4.3 7.6-4.3s6 1.6 7.6 4.3L461.9 410c1.4 2.3 2.1 4.9 2.1 7.5c0 8-6.5 14.5-14.5 14.5H62.5c-8 0-14.5-6.5-14.5-14.5c0-2.7 .7-5.3 2.1-7.5L248.4 84.3zm-41-25L9.1 385c-6 9.8-9.1 21-9.1 32.5C0 452 28 480 62.5 480h387c34.5 0 62.5-28 62.5-62.5c0-11.5-3.2-22.7-9.1-32.5L304.6 59.3C294.3 42.4 275.9 32 256 32s-38.3 10.4-48.6 27.3zM288 368a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm-8-184c0-13.3-10.7-24-24-24s-24 10.7-24 24v96c0 13.3 10.7 24 24 24s24-10.7 24-24V184z"]
  };
  var faExclamationTriangle = faTriangleExclamation;

  exports.faBars = faBars;
  exports.faCaretRight = faCaretRight;
  exports.faCheck = faCheck;
  exports.faEllipsisH = faEllipsisH;
  exports.faEllipsisV = faEllipsisV;
  exports.faEnvelope = faEnvelope;
  exports.faExclamationTriangle = faExclamationTriangle;
  exports.faEye = faEye;
  exports.faLocationArrow = faLocationArrow;
  exports.faMagic = faMagic;
  exports.faMapMarkerAlt = faMapMarkerAlt;
  exports.faMinus = faMinus;
  exports.faPencil = faPencil;
  exports.faPlus = faPlus;
  exports.faRedo = faRedo;
  exports.faRepeat = faRepeat;
  exports.faTimes = faTimes;
  exports.faTrash = faTrash;

  Object.defineProperty(exports, '__esModule', { value: true });

}));
